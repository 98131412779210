<template>
  <div class="w-100 h-100-vh">
    <div class="toaster show">
      <div
        class="toaster__container"
        v-if="alert.show"
        v-bind:class="{
          'toaster--success': alert.status === 'success',
          'toaster--error': alert.status === 'fail',
        }"
      >
        <div class="text-right">
          <span class="fa fa-times" @click="alert.show = false"></span>
        </div>
        <div>
          <span class="d-block text--small text--600 text--white">{{
            alert.title
          }}</span>
          <span class="d-block text--tiny text--400 text--white">{{
            alert.description
          }}</span>
        </div>
      </div>
    </div>
    <div class="dashboard_card">
      <div class="dashboard_card_header">
        <h5 class="dashboard_card_header__title text--bold text--capital">
          Activities
        </h5>
        <button
          type="button"
          class="button button--grey text--capital"
          data-toggle="modal"
          data-target="#activityModal"
          data-backdrop="static"
        >
          <span class="d-inline-flex mr-2">Configure Activity feeds</span>
          <img src="@/assets/img/settings.svg" alt="" srcset="" />
        </button>
      </div>
      <div class="right__body">
        <form>
          <div>
            <div
              v-for="item in dashboard.userActivities"
              class="form__item"
              :key="item.activity_key"
            >
              <div class="row">
                <div class="col-1">
                  <img src="@/assets/img/login.svg" alt="" srcset="" />
                </div>
                <div class="col-11">
                  <p>
                    <span
                      class="d-inline-block text--black text--regular-alt text--600"
                      >{{ dashboard.userName }}</span
                    >
                    &nbsp;<span
                      class="d-inline-block text--regular text--dark text--opaq1 text--capital text-300"
                      >{{ item.activity_notes }}</span
                    >
                  </p>
                  <span class="text--tiny text--dark text-300 text--opaq1">
                    {{ getMinsAgo(item.creation_date) }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div
        class="modal fade"
        id="activityModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="activityModalLabel"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header">
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  width: 100%;
                "
              >
                <div style="width: 90%; text-align: center">
                  <h5
                    class="modal__head__title text--capital text--center"
                    id="activityModalLabel"
                  >
                    Configure Dashboard Activity Feeds
                  </h5>
                </div>

                <div style="width: 8%">
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    @click="closeModal"
                  >
                    <span style="font-size: 2.5rem" aria-hidden="true">
                      &times;
                    </span>
                  </button>
                </div>
              </div>
            </div>

            <div class="modal-body">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-12">
                    <div class="form__item">
                      <label class="form__label">Activity Type</label>
                      <div
                        style="
                          display: flex;
                          justify-content: space-between;
                          width: 100%;
                        "
                      >
                        <div style="width: 70%">
                          <multiselect
                            v-model="activityList"
                            placeholder="Select Activity"
                            track-by="activityTypeKey"
                            :options="allActivity.data"
                            :preserve-search="true"
                            :preselect-first="false"
                            :multiple="false"
                            :close-on-select="true"
                            :clear-on-select="true"
                            :max="0"
                            :optionHeight="10"
                            label="displayActivityName"
                            @input="addActivity"
                            @search-change="saveNewValue"
                            class="select select--lg"
                          >
                            <span slot="noResult">Oops! No result found.</span>
                            <span slot="noOptions"
                              >Start typing to display list</span
                            >
                          </multiselect>
                          <label
                            v-if="showError"
                            style="font-size: 10px; color: red"
                            class="form__label"
                          >
                            Already in the list
                          </label>
                        </div>

                        <div style="width: 25%">
                          <button
                            type="button"
                            class="button ml-auto page__body__header__button button--black text--capital"
                            @click="addActivity"
                          >
                            <span class="d-inline-flex mr-2"
                              >add activity type</span
                            >
                            <ion-icon
                              name="add-outline"
                              class="text--regular"
                            ></ion-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-12">
                    <label class="form__label">Selected Activity Types</label>
                    <div
                      style="
                        border: 1px dashed black;
                        height: 300px;
                        overflow: auto;
                        width: 100%;
                      "
                    >
                      <div
                        style="width: 100%"
                        v-for="item in allConfigureActivity"
                        v-bind:key="item.activityTypeKey"
                      >
                        <div
                          class="hover--text text--capital page__body__header__button"
                          style="
                            display: flex;
                            padding-right: 10px;
                            padding-left: 10px;
                            width: 100%;
                          "
                        >
                          <div style="width: 90%">
                            <span
                              style="
                                justify-content: flex-start;
                                height: 30px;
                                cursor: pointer;
                              "
                            >
                              {{ item.displayActivityName }}
                            </span>
                          </div>
                          <div style="width: 10%">
                            <button
                              type="button hover--text"
                              class="close"
                              @click="removeActivity(item.activityTypeKey)"
                            >
                              <span
                                class="hover--text"
                                style="font-size: 3rem"
                                aria-hidden="true"
                              >
                                &times;
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="modal-footer">
              <button
                type="button"
                id="closeModal"
                class="button button--md modal__confirm__button modal__confirm__button--cancel mr-2"
                style="height: 44px"
                data-dismiss="modal"
                @click="closeModal"
              >
                Close
              </button>
              <button
                data-dismiss="#confirmModal"
                class="button form__button form__button--md save-changes fitrem"
                v-if="isSaving"
              >
                <div class="spinner-border texxt-light" role="status"></div>
              </button>
              <button
                type="button"
                v-else
                class="button form__button form__button--sm save-changes modal__confirm__button--save fitrem"
                @click="saveActivity"
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import ApiService from "@/core/services/api.service";
import Multiselect from "vue-multiselect";
import { mapState } from "vuex";
import { GET_ALL_ACTIVITIES } from "@/core/services/store/activity.module";
export default {
  name: "Activities",
  props: {
    dashboard: Object,
  },
  components: { Multiselect },
  data() {
    return {
      activityList: "",
      isSaving: false,
      allConfigureActivity: [],
      showError: false,
      getSearchValue: "",
      alert: {
        status: "",
        title: "",
        description: "",
        show: false,
      },
    };
  },
  methods: {
    getMinsAgo(date) {
      return moment(date).fromNow();
    },
    saveActivity() {
      this.isSaving = true;
      let data = {
        activityTypeList: this.allConfigureActivity,
      };

      ApiService.post(
        "dashboardIndicators/CreateUpdateUserActivityTypeSettingsOnDashBoard",
        data
      )
        .then((response) => {
          this.getAllConfiguredActivity();
          this.isSaving = false;
          if (response.data.status == true) {
            this.alertSuccess(response.data.message);
          } else {
            this.alertError(response.data.message);
          }
          this.$emit("configureSuccess");
          document.getElementById("closeModal").click();
        })
        .catch(() => {
          this.isSaving = false;
          this.alertError("Update failed.");
        });
    },
    saveNewValue(searchQuery) {
      this.getSearchValue = searchQuery;
    },
    addActivity() {
      this.showError = false;

      let checkActivity = this.allConfigureActivity.find(
        (act) => act.activityTypeKey === this.activityList.activityTypeKey
      );
      if (checkActivity) {
        this.showError = true;
      } else if (this.activityList != "") {
        this.allConfigureActivity.push(this.activityList);
      }
    },
    closeModal() {
      this.activityList = "";
      this.showError = false;
      this.getAllConfiguredActivity();
    },
    createActivity() {
      this.showError = false;
      let checkActivity = this.allConfigureActivity.find(
        (act) =>
          act.displayActivityName.toLowerCase() ===
          this.getSearchValue.toLowerCase()
      );

      if (checkActivity) {
        this.showError = true;
      } else if (this.getSearchValue != "") {
        let obj = {};
        obj.activityTypeKey = Math.floor(100000 + Math.random() * 900000);
        obj.displayActivityName = this.getSearchValue;
        this.allConfigureActivity.push(obj);
        this.activityList = "";
        this.getSearchValue = "";
      }
    },
    removeActivity(key) {
      this.allConfigureActivity = this.allConfigureActivity.filter(
        (act) => act.activityTypeKey !== key
      );
      this.activityList = "";
    },
    getAllActivity() {
      this.$store.dispatch(GET_ALL_ACTIVITIES);
    },
    getAllConfiguredActivity() {
      ApiService.get(`dashboardIndicators/getuseractivityconfigurationsettings`)
        .then((res) => {
          this.allConfigureActivity = res.data.data ? res.data.data : [];
        })
        .catch(() => {});
    },
    alertSuccess(description) {
      this.alert.status = "success";
      this.alert.title = "Success";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },

    alertError(description) {
      this.alert.status = "fail";
      this.alert.title = "Error";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
  },
  computed: {
    ...mapState({
      allActivity: (state) => {
        return state.activity.allActivity;
      },
      loading: (state) => {
        return state.activity.loading;
      },
    }),
  },
  mounted() {
    this.getAllActivity();
    this.getAllConfiguredActivity();
  },
};
</script>
<style scoped>
.h-100-vh {
  height: 100vh;
}
.hover--text {
  color: #000;
}
.hover--text:hover {
  background: #1e1f20 !important;
  color: #fff;
}
</style>
